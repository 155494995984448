module.exports = {
  MODE: "production",
  STAGE: "production",
  API_URL: "https://app.uk.spokeapp.io/api/v1",
  ASSETS_URL: "https://app.uk.spokeapp.io/static",
  FACEBOOK_APP_ID: "1452511548179700",
  STRIPE_API_KEY_AU: "pk_live_MbbtOJWy2zyLG0rIroQ0mdhP",
  STRIPE_API_KEY_NZ: "pk_live_MbbtOJWy2zyLG0rIroQ0mdhP",
  STRIPE_API_KEY_GB: "pk_live_lTo1SR4HGPOYDLNDyELDk7AO008qBplB3E",
  EMBED_API:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjU0Yjk4YmYxYjA1ZDgyMzkwMTdiMWRjOGI5OWFiOGQwNGRiMjJlM2M2YWU2NzcyM2IyMGQ5MjNjZDMyYjEzMGI1OGNiYThiOGQ1ZTc0ZDNlIn0.eyJhdWQiOiIzIiwianRpIjoiNTRiOThiZjFiMDVkODIzOTAxN2IxZGM4Yjk5YWI4ZDA0ZGIyMmUzYzZhZTY3NzIzYjIwZDkyM2NkMzJiMTMwYjU4Y2JhOGI4ZDVlNzRkM2UiLCJpYXQiOjE1NzYwNDYwMzYsIm5iZiI6MTU3NjA0NjAzNiwiZXhwIjo0MTAyNDQ0ODAwLCJzdWIiOiIwMDE5YjQ0YS0xYmUwLTExZWEtOWE3MS02NjgyOGE4MzgwNzIiLCJzY29wZXMiOltdfQ.Xo0l7cYcIzH2sUFMTlPtSzPLPOHobXDpSrgKMRi3u9LRYCIalW6UZKvFBVlER0_QzNgE1DrEQomlD6idB8I3wmnW7z0JxKZD6tYpuCCD1txluzPAVQRMQDhGSdlQRpD8fZW6obxtubGHMR9AZdg0n9Ey8Akr7RBQzt7Qq8Dr41CnL9L6IHfF0zNTRMOav0w9nJfhEVI1fSKTg6B4IiGSqvdFL6Ttd-uGOlPAjN3djJm3DjIoBZHs9WIbZ746S19Z58_NRPqVC7SB2i02DfIJYIRAcy6wv85VwA0I_XgU2ZCvmS-OvQsVJoZpFycWYnO6UYHgX-4f9RuJAR0JT9NNDotniTtxBmYgrhdS2yCUOtspN_kem-ow5D0Hn5uoa5yaCYGXqGuVS2BtZOnRXmqYbeacHL8rDQKhc46ewTzUW4eoOE8HtmUp4ZBVuumCOaZdm471UAETAmt_qTMarqm0QqUfDSxUVxcZPhfT_pUH7-qqq7bZf_4AXzDrpYFfvEJVQw5ZBK-LcWNRwDnmMUVUo4fMZrtn6gwwMfgmHy16kDzP11bBmiGKtm4XkyHms6QeZAStenVrkiGmodgAvsxSPPn_G-dLAUIA66MAB2wzGrVsjZ76aB6lV788EktmsyllCi_HFSeEQHsbWWvbHjW9wIB1p6KMLpRnpRtd0Yf9OO8",
  SEGMENT_ID: "h4gGxuQUMswkgJLImlwwIUsAOpNdbWuJ",
  SPRIG_ENVIRONMENT_ID: "02sSarBDnp",
  ZENDESK_MESSENGER_API_KEY: '4d070ed4-8b84-4009-807e-0365235bc15e',
  OAUTH: {
    CLIENT_ID: "2",
    CLIENT_SECRET: "aYIoNwBxsZZKk4jNx5GWRqq5lWrXYbkpatKTvz54",
  },
  PUSHER_KEY: "473a0abd256ad6636962",
  PUSHER_CLUSTER: "ap4",
  MAPBOX_TOKEN:
    "pk.eyJ1IjoicmV4c29mdHdhcmUiLCJhIjoiY2pzZTdvdGUzMTNxajQ2b2FjMzFqd3hscCJ9.8w_bJa4-lDg90X9yuXSbDQ",
  FULL_STORY_ID: "AX2QJ",
  BUGSNAG_ID: "5c75343e2e6fea948226e0b70e7cd550",
  SHOW_RETARGETING: false,
  GTM_ID: "GTM-WDXCSGB",
  LANDING_PAGE_PREVIEW: "https://app.uk.spokeapp.io/landing-pages/preview",
  INTERCOM_ID: "nup818uj",
  MIN_AUDIENCE_RADIUS: 9,
  HUBSPOT_BOOKING_URL:
    "https://meetings.hubspot.com/alex-lacey",
  HUBSPOT_ACCOUNT_MANAGER_NAME: "Alex Lacey",
  HUBSPOT_ACCOUNT_MANAGER_EMAIL: "alex.lacey@rexsoftware.com",
  RELEASE: {
    STAGE: "__RELEASE_STAGE__",
    VERSION: "__RELEASE_VERSION__",
    HASH: "__RELEASE_HASH__",
  },
};
